import { FC, useCallback, useMemo } from 'react';
import { FilterProps } from './FilterProps';
import { useTranslation } from 'react-i18next';
import { useTableViewFilters } from '../../../contexts/table-view/TableViewFilterContext';
import { YesNoFilterValue } from '../../../models/TableViewFilters';
import Checkbox from '../../shared/form-control/Checkbox';

const YesNoFilter: FC<FilterProps> = (props) => {
  const { columnConfig, selectedTemplateId } = props;
  const { setFilters, filters } = useTableViewFilters();

  const { t } = useTranslation(['activity-type', 'table-view']);

  const options = useMemo(() => {
    return [
      {
        id: -1,
        text: t('table-view:filters.no-data'),
        value: false,
      },
      {
        id: 0,
        text: t('yes-no.yes'),
        value: 'yes',
      },
      {
        id: 1,
        text: t('yes-no.no'),
        value: 'no',
      },
      {
        id: 2,
        text: t('yes-no.not-applicable'),
        value: 'na',
      },
    ] as const;
  }, [t]);

  const filterValue = useMemo(() => {
    return (filters?.[selectedTemplateId]?.[columnConfig.value]?.filter as YesNoFilterValue) ?? [];
  }, [columnConfig.value, filters, selectedTemplateId]);

  const isSelected = useCallback(
    (value: YesNoFilterValue[number]) => {
      return filterValue?.includes(value) ?? false;
    },
    [filterValue],
  );

  const selectOption = useCallback(
    (value: YesNoFilterValue[number], selected: boolean) => {
      setFilters(columnConfig, selectedTemplateId, selected ? filterValue.concat(value) : filterValue.filter((x) => x !== value));
    },
    [columnConfig, filterValue, selectedTemplateId, setFilters],
  );

  return (
    <div className="-mr-4 h-64 w-72 space-y-4 overflow-y-auto">
      {options.map((x) => (
        <Checkbox key={x.id} value={isSelected(x.value)} onChange={(v) => selectOption(x.value, v)} label={x.text} />
      ))}
    </div>
  );
};

export default YesNoFilter;
